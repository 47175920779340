import { Popup } from "react-map-gl/maplibre";
import { MapGeoJSONFeature } from "maplibre-gl";
import centerOfMass from "@turf/center-of-mass";
import bbox from "@turf/bbox";
import buffer from "@turf/buffer";
import { min_width_820, useMediaQuery } from "../hooks/useMediaQuery";
import CloseIcon from "../Components/Icons/CloseIcon";
// import MapPinIcon from "../Components/Icons/MapPinIcon";

const baseurl = process.env.REACT_APP_SKRAAFOTO_API_BASEURL;
const token = process.env.REACT_APP_SKRAAFOTO_API_TOKEN;

export default function MapPopup(props: {
  feature: MapGeoJSONFeature;
  close: () => void;
}) {
  const { feature, close } = props;
  const { properties } = feature;
  const {
    area,
    ejendomvaerdibeloeb_sum,
    groundtax,
    groundvalue,
    grundvaerdibeloeb_sum,
    propertytax,
    propertyvalue,
    totaladdresstax,
    ejendomsvaerdi_2020,
    grundvaerdi_2020,
    beliggenhed,
  } = properties;
  // console.log(properties);

  const center = centerOfMass(feature);
  const matches = useMediaQuery(min_width_820);
  const skraafotoUrl = `${baseurl}api/image_of_area?bbox=${bbox(
    buffer(feature, 10, { units: "meters" })
  ).toString()}&epsg=4326&token=${token}&direction=n&width=330&height=150`;
  if (matches) {
    return (
      <Popup
        style={{ maxWidth: "unset" }}
        latitude={center.geometry.coordinates[1]}
        longitude={center.geometry.coordinates[0]}
        closeButton={false}
        offset={10}
        closeOnClick={false}
      >
        <div className="c-map-popup">
          <Contents
            area={area}
            ejendomvaerdibeloeb_sum={ejendomvaerdibeloeb_sum}
            groundtax={groundtax}
            groundvalue={groundvalue}
            grundvaerdibeloeb_sum={grundvaerdibeloeb_sum}
            propertytax={propertytax}
            propertyvalue={propertyvalue}
            totaladdresstax={totaladdresstax}
            skraafotoUrl={skraafotoUrl}
            grundvaerdi_2020={grundvaerdi_2020}
            ejendomsvaerdi_2020={ejendomsvaerdi_2020}
            beliggenhed={beliggenhed}
          />
        </div>
      </Popup>
    );
  }
  return (
    <div className="c-map-popup-overlay">
      <div className="c-map-popup c-map-popup--sm">
        <button onClick={close} title="Luk vindue" className="btn-close">
          <CloseIcon />
        </button>
        <Contents
          area={area}
          ejendomvaerdibeloeb_sum={ejendomvaerdibeloeb_sum}
          groundtax={groundtax}
          groundvalue={groundvalue}
          grundvaerdibeloeb_sum={grundvaerdibeloeb_sum}
          propertytax={propertytax}
          propertyvalue={propertyvalue}
          totaladdresstax={totaladdresstax}
          skraafotoUrl={skraafotoUrl}
          grundvaerdi_2020={grundvaerdi_2020}
          ejendomsvaerdi_2020={ejendomsvaerdi_2020}
          beliggenhed={beliggenhed}
        />
      </div>
    </div>
  );
}

function Contents(props: {
  area: number | undefined;
  ejendomvaerdibeloeb_sum: number | undefined;
  groundtax: number | undefined;
  groundvalue: number | undefined;
  grundvaerdibeloeb_sum: number | undefined;
  propertytax: number | undefined;
  propertyvalue: number | undefined;
  totaladdresstax: number | undefined;
  skraafotoUrl: string | undefined;
  ejendomsvaerdi_2020: number | string | undefined;
  grundvaerdi_2020: number | string | undefined;
  beliggenhed: string | undefined;
}) {
  const {
    // area,
    ejendomvaerdibeloeb_sum,
    groundtax,
    groundvalue,
    grundvaerdibeloeb_sum,
    propertytax,
    propertyvalue,
    totaladdresstax,
    skraafotoUrl,
    ejendomsvaerdi_2020,
    grundvaerdi_2020,
    beliggenhed,
  } = props;

  return (
    <>
      <div className="c-map-popup__img">
        <img
          src={skraafotoUrl}
          alt="Skråfoto af ejendommen"
          width={330}
          height={150}
        />
      </div>
      <div className="c-map-popup__texts text-dark">
        <h3 className="text-dark fw-bold c-map-popup__heading px-1">
          {/* <MapPinIcon /> */}
          <span>
            {beliggenhed !== undefined && beliggenhed}
            {beliggenhed === undefined && "Ingen adresse at vise"}
          </span>
        </h3>

        <div>
          <div className="py-1 px-1">
            <h4 className="smaller text-dark text-uppercase fw-bold mb-1">
              Tidligere vurdering
            </h4>
            <dl>
              <div className="d-flex justify-content-space-between">
                <dt className="fw-medium mb-1">Ejendomsværdi:</dt>
                <dd className="h3 fw-semibold">
                  {ejendomvaerdibeloeb_sum !== undefined &&
                    ejendomvaerdibeloeb_sum.toLocaleString("da-DK") + " kr"}
                  {ejendomvaerdibeloeb_sum === undefined && "-"}
                </dd>
              </div>
              <div className="d-flex justify-content-space-between">
                <dt className="fw-medium">Grundværdi:</dt>
                <dd className="h3 fw-semibold">
                  {grundvaerdibeloeb_sum !== undefined &&
                    grundvaerdibeloeb_sum.toLocaleString("da-DK") + " kr"}
                  {grundvaerdibeloeb_sum === undefined && "-"}
                </dd>
              </div>
            </dl>
          </div>
        </div>

        <div>
          <div className="pt-2 pb-1 px-1">
            <h4 className="smaller text-dark text-uppercase fw-bold mb-1">
              2020-vurdering
            </h4>
            <dl>
              <div className="d-flex justify-content-space-between">
                <dt className="fw-medium mb-1">Ejendomsværdi:</dt>
                <dd className="h3 fw-semibold">
                  {!ejendomsvaerdi_2020 || ejendomsvaerdi_2020 === "N/A"
                    ? (<span className="text-muted small">Ikke nyvurderet</span>)
                    : ejendomsvaerdi_2020.toLocaleString("da-DK") + " kr"}
                </dd>
              </div>
              <div className="d-flex justify-content-space-between">
                <dt className="fw-medium">Grundværdi:</dt>
                <dd className="h3 fw-semibold">
                  {!grundvaerdi_2020 || grundvaerdi_2020 === "N/A"
                    ? (<span className="text-muted small">Ikke nyvurderet</span>)
                    : grundvaerdi_2020.toLocaleString("da-DK") + " kr"}
                </dd>
              </div>
            </dl>
          </div>
        </div>

        <div>
          <div className="pt-2 px-1 pb-3">
            <h4 className="smaller text-dark text-uppercase fw-bold mb-1">
              Foreløbig 2022-vurdering
            </h4>
            <dl>
              <div className="d-flex justify-content-space-between">
                <dt className="fw-medium mb-1">Ejendomsværdi:</dt>
                <dd className="h3 fw-semibold">
                  {propertyvalue !== undefined &&
                    propertyvalue.toLocaleString("da-DK") + " kr"}
                  {propertyvalue === undefined && "-"}
                </dd>
              </div>
              <div className="d-flex justify-content-space-between">
                <dt className="fw-medium">Grundværdi:</dt>
                <dd className="h3 fw-semibold">
                  {groundvalue !== undefined &&
                    groundvalue.toLocaleString("da-DK") + " kr"}
                  {groundvalue === undefined && "-"}
                </dd>
              </div>
            </dl>
          </div>
        </div>

        <div className="bg-light rounded">
          <div className="py-2 px-2">
            <h4 className="smaller text-dark text-uppercase fw-bold">
              Ny boligskat 2024
            </h4>
            <p className="smaller text-secondary fw-semibold mb-1 pb-1">
              (hvis du køber boligen efter 1. januar 2024)
            </p>
            <dl>
              <div className="d-flex justify-content-space-between">
                <dt className="fw-medium mb-1">Ejendomsværdiskat:</dt>
                <dd className="h3 fw-semibold">
                  {propertytax !== undefined && (
                    <>{propertytax.toLocaleString("da-DK")} kr</>
                  )}
                  {propertytax === undefined && "-"}
                </dd>
              </div>
              <div className="d-flex justify-content-space-between">
                <dt className="fw-medium">Grundskyld:</dt>
                <dd className="h3 fw-semibold">
                  {groundtax !== undefined && (
                    <>{groundtax.toLocaleString("da-DK")} kr</>
                  )}
                  {groundtax === undefined && "-"}
                </dd>
              </div>
            </dl>
          </div>
          <div className="py-2 px-2 border-top">
            <dl className="d-flex justify-content-space-between">
              <dt className="fw-medium">Samlet boligskat:</dt>
              <dd className="h3 fw-bold">
                {totaladdresstax !== undefined && (
                  <>{totaladdresstax.toLocaleString("da-DK")} kr</>
                )}
                {totaladdresstax === undefined && "-"}
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </>
  );
}
