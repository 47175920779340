export default function LegendIcon() {
  return (
    <svg
      role="img"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      width={20}
      height={20}
      fill="currentColor"
    >
      <path d="M480-60.782q-85.863 0-162.301-33.195-76.439-33.196-133.482-90.24-57.044-57.043-90.24-133.493-33.195-76.45-33.195-162.325 0-86.922 33.63-163.313 33.631-76.392 91.674-133.218 58.044-56.826 135.395-89.739 77.352-32.913 165.157-32.913 83.623 0 158.297 28.913 74.674 28.913 131.283 79.956 56.609 51.044 89.805 120.766 33.195 69.722 33.195 150.453 0 118.391-70.848 188.37-70.847 69.978-178.196 69.978h-60.999q-6.739 0-9.956 3.87-3.218 3.869-3.218 9.304 0 10.87 11.609 26.587 11.608 15.717 11.608 41.891 0 54.521-33.717 86.435Q531.783-60.782 480-60.782ZM480-480Zm-213.217 40q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm116.608-154.913q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm193.218 0q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17ZM691.521-440q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17ZM476.609-166.783q7.728 0 12.451-4.372 4.722-4.372 4.722-11.367 0-14-15.282-31.022-15.283-17.021-15.283-52.76 0-44.826 30.967-72.653 30.967-27.826 75.816-27.826h80.174q64.87 0 103.956-37.935 39.087-37.934 39.087-110.456 0-121-91.369-199.521-91.37-78.522-215.215-78.522-132.62 0-226.235 91.304-93.615 91.304-93.615 222.184 0 129.903 90.674 221.424 90.673 91.522 219.152 91.522Z"/>
    </svg>
  );
}
