import Controller from "@septima/septima-search/Controller";
import DawaSearcher from "@septima/septima-search/searchers/DawaSearcher";
import S3Searcher from "@septima/septima-search/searchers/S3Searcher";
import CvrSearcher from "@septima/septima-search/searchers/data-api/CvrSearcher";
import Fetcher from "@septima/septima-search/searchers/data-api/Fetcher";

export const ssLib = {
  Controller,
  searchers: { DawaSearcher, S3Searcher, CvrSearcher },
  Fetcher,
};
export { default as SeptimaSearch } from "./SeptimaSearch";
export const searchController = new ssLib.Controller(
  [
    new ssLib.searchers.DawaSearcher({
      crs: "EPSG:4326",
    }),
  ],
  {
    blankBehavior: "search",
  }
);
