import { forwardRef } from "react";
import { ISeptimaSearchResultItem } from "../interfaces/types";

interface IResultItemProps
  extends React.DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement> {
  item: ISeptimaSearchResultItem;
  highlighted: boolean;
}
const ResultItem = forwardRef((props: IResultItemProps, ref) => {
  const { item, highlighted, ...rest } = props;
  return (
    <li
      className={`septima-search-result-item${
        highlighted ? " septima-search-result-item-active" : ""
      }`}
      role="option"
      aria-selected={highlighted}
      {...rest}
    >
      {/* <img className="septima-search-result-item-image" src={item.image} alt="item" /> */}
      <div className="septima-search-result-item-heading">
        <div className="septima-search-result-item-title">{item.title}</div>
        <div className="septima-search-result-item-subtitle">{item.description}</div>
      </div>
      {item.newquery && (
        <svg
        className="arrow-right"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
        </svg>
      )}
    </li>
  );
});

export default ResultItem;
