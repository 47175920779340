import React, { useState, MouseEvent } from "react";
import { Range, gradients } from "../util/color";

export interface ILegendProps {
  range: Range;
  legendOpacity: number;
  grundEllerEjendom: "grund" | "ejendom";
}
export default function Legend(props: ILegendProps) {
  const { range, legendOpacity, grundEllerEjendom } = props;
  return (
    <div className="card c-legend pt-3">
      <p className="text-muted text-uppercase fw-bold smaller mb-1">
        Farver i kortet
      </p>
      <h3 className="h4 text-dark fw-bold">
        {grundEllerEjendom === "ejendom" ? "Ejendomsværdi" : "Grundværdi"} per
        kvadratmeter grundareal
      </h3>
      <LegendBar
        highValue={range.high}
        lowValue={range.low}
        legendOpacity={legendOpacity}
      />
    </div>
  );
}

export function LegendBar(props: {
  highValue: number;
  lowValue: number;
  legendOpacity: number;
}) {
  const { highValue, lowValue, legendOpacity } = props;

  const gradientSteps = gradients["skala"];
  const grads = gradientSteps.reduce((prev, curr) => {
    return prev + `${curr.color.toString()} ${curr.stepValue}%,`;
  }, "");
  const [pct, setPct] = useState(0);
  function getPos(e: MouseEvent): void {
    let range = document.querySelector("#range");
    if (range) {
      let width = range?.getBoundingClientRect();
      let x = e.clientX - width.left;
      let pct = (100 * x) / width.width;
      setPct(Math.trunc(pct));
    }
  }
  return (
    <>
      <div
        className="c-legend__bar"
        id="range"
        onMouseMove={(e) => getPos(e)}
        onMouseLeave={() => setPct(0)}
        style={{ opacity: legendOpacity }}
      >
        <div
          className="c-legend__bar-inner"
          style={{
            background: `linear-gradient(90deg, ${grads.substring(
              0,
              grads.length - 1
            )})`,
          }}
        ></div>
        {gradientSteps.map((step, index) => {
          if (index === 0) return null;
          return (
            <div
              key={index}
              style={{
                zIndex: 2,
                flex:
                  gradientSteps[index].stepValue -
                  gradientSteps[index - 1].stepValue,
                borderRight: "solid white 1px",
                marginRight: index === gradientSteps.length - 1 ? -1 : 0,
              }}
            ></div>
          );
        })}
        <div
          style={{ left: `${pct - 4}%` }}
          className={`c-legend__tip ${
            pct === 0 ? "c-legend__tip--hidden" : ""
          }`}
        >
          {Math.floor(
            (highValue - lowValue) * (pct / 100) + lowValue
          ).toLocaleString("da-DK")}{" "}
          kr
        </div>
      </div>
      <div
        className="c-legend__labels text-muted smaller d-flex justify-content-between mt-1 fw-semibold"
        style={{ justifyContent: "space-between" }}
      >
        <span>{lowValue.toLocaleString("da-DK")} kr</span>
        <span>{highValue.toLocaleString("da-DK")} kr</span>
      </div>
    </>
  );
}
