export default function SeptimaWatermark() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width="110"
      height="30"
      viewBox="0 0 190.534 52.072"
    >
      <g data-name="Group 2475">
        <g data-name="Layer 1-2" opacity=".1">
          <path
            fill="#00283b"
            d="M26.572 48.542a4.013 4.013 0 0 1-.588-.5 3.983 3.983 0 0 1-2.574.936H4a4 4 0 0 1-4-4V25.569a4.018 4.018 0 0 1 .147-1.078A4.019 4.019 0 0 1 0 23.413V4.004a4 4 0 0 1 4-4h19.409a4 4 0 0 1 1.08.147 4 4 0 0 1 1.08-.147h19.409a4 4 0 0 1 4 4v19.409a3.986 3.986 0 0 1-.988 2.633 14.227 14.227 0 0 1 1.795 1.013 4 4 0 0 1 1.729 2.669c.183 1.09-.464 6.679.176-.85a4 4 0 0 1 4-4h17.612a3.993 3.993 0 0 1 3.2 1.6 4.038 4.038 0 0 1 .381-.439 4.015 4.015 0 0 1 2.827-1.164h14.222a10.4 10.4 0 0 1 4.949 1.161 3.987 3.987 0 0 1 2.743-1.089h19.7a3.98 3.98 0 0 1 2.025.55 3.982 3.982 0 0 1 2.145-.623h1.725a4 4 0 0 1 3.918 3.191 4 4 0 0 1 3.919-3.191h2.66a4 4 0 0 1 3.176 1.569l6.458 8.433 6.457-8.433a4 4 0 0 1 3.183-1.565h2.588a4 4 0 0 1 2.829 1.172 4 4 0 0 1 1.172 2.829v11.5l7.057-13.3a4 4 0 0 1 3.533-2.125h2.516a4 4 0 0 1 3.544 2.146l9.853 18.834a4 4 0 0 1-.122 3.926 4 4 0 0 1-3.422 1.929h-1.948a4 4 0 0 1-3.534-2.129l-1.463-2.761h-8.484l-1.463 2.761a4 4 0 0 1-3.535 2.129h-1.941a4 4 0 0 1-2.3-.727 3.981 3.981 0 0 1-2.3.727h-1.725a4 4 0 0 1-4-4v-5.662l-3.337 4.3a4 4 0 0 1-3.154 1.549 4.007 4.007 0 0 1-3.159-1.538l-3.415-4.374v5.723a4 4 0 0 1-4 4h-1.721a4 4 0 0 1-3.919-3.191 4 4 0 0 1-3.918 3.191h-1.725a4 4 0 0 1-4-4V34.53h-5.157v13.248a4 4 0 0 1-4 4h-1.725a4 4 0 0 1-4-4V34.525h-3a9.242 9.242 0 0 1-2.923 6.9 10.293 10.293 0 0 1-7.188 2.585h-8.079v3.764a4 4 0 0 1-4 4H79.7a3.988 3.988 0 0 1-2.839-1.182 4 4 0 0 1-2.84 1.183H55.69a4 4 0 0 1-4-4V46.7a9.723 9.723 0 0 1-3.087 3.134c-2.3 1.5-5.37 2.233-9.377 2.233-5.063.005-9.32-1.183-12.654-3.525Z"
            data-name="Path 1328"
          />
        </g>
        <g
          fill="none"
          stroke="#00283b"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="3"
          data-name="Layer 1-2"
          opacity=".35"
        >
          <path
            d="M46.292 31.894a16.393 16.393 0 0 0-7.837-1.87 11.935 11.935 0 0 0-5.392.933 2.964 2.964 0 0 0-1.87 2.661 2.493 2.493 0 0 0 1.8 2.444 17.051 17.051 0 0 0 5.392.792 36.344 36.344 0 0 1 4.386.289 11.315 11.315 0 0 1 3.306.719 4.185 4.185 0 0 1 2.731 4.17 5.039 5.039 0 0 1-2.517 4.459c-1.653 1.078-4.1 1.581-7.189 1.581q-6.363 0-10.353-2.8l1.006-1.439a16.553 16.553 0 0 0 8.987 2.589 12.7 12.7 0 0 0 6.039-1.15 3.525 3.525 0 0 0 2.086-3.236 2.755 2.755 0 0 0-1.942-2.731 17.987 17.987 0 0 0-5.75-.933 35.388 35.388 0 0 1-4.17-.289 13.477 13.477 0 0 1-3.092-.647 3.729 3.729 0 0 1-2.731-3.739 4.456 4.456 0 0 1 2.372-4.026c1.581-1.006 3.811-1.439 6.759-1.439 3.953 0 6.973.719 9.131 2.156l-1.15 1.509h0Z"
            data-name="Path 1318"
          />
          <path
            d="M73.896 47.781H55.564V28.874h17.612v1.581H57.217v6.47h11.07v1.581h-11.07v7.55h16.607l.072 1.725Z"
            data-name="Path 1319"
          />
          <path
            d="M93.811 28.874a5.712 5.712 0 0 1 4.17 1.509 5.537 5.537 0 0 1 1.509 4.1 5.2 5.2 0 0 1-1.653 4.026 6.321 6.321 0 0 1-4.459 1.509H81.3v7.764h-1.725v-18.91l14.236.002Zm-.144 9.562a3.99 3.99 0 0 0 2.875-1.006 4.175 4.175 0 0 0 1.006-2.948c0-2.661-1.509-3.953-4.528-3.953H81.23v7.909h12.437Z"
            data-name="Path 1320"
          />
          <path
            d="M121.202 30.526h-8.987V47.78h-1.725V30.526h-8.987v-1.58h19.7v1.58h0Z"
            data-name="Path 1321"
          />
          <path
            d="M127.097 47.781h-1.725V28.874h1.725Z"
            data-name="Path 1322"
          />
          <path
            d="M159.449 47.781h-1.725V30.524h-.072l-10.426 13.442-10.5-13.442h-.072v17.254h-1.725V28.874h2.661l9.634 12.581 9.634-12.581h2.589v18.907h0Z"
            data-name="Path 1323"
          />
          <path
            d="M186.409 47.78h-1.942l-2.589-4.889h-13.3l-2.589 4.889h-1.942l9.992-18.834h2.517l9.854 18.834h0Zm-5.392-6.542-5.68-10.712h-.289l-5.678 10.712Z"
            data-name="Path 1324"
          />
          <path
            d="m20.84 21.468-2.8-2.8.5-.5 2.8 2.8a5 5 0 0 1 1.944-1.008V4H3.875v19.41h15.959a3.887 3.887 0 0 1 1.006-1.942Z"
            data-name="Path 1325"
          />
          <path
            d="M25.443 4v15.96a3.887 3.887 0 0 1 1.942 1.006l2.8-2.8.5.5-2.8 2.8a5 5 0 0 1 1.006 1.942h15.962V4Z"
            data-name="Path 1326"
          />
          <path
            d="m21.343 28.006-2.8 2.8-.5-.5 2.8-2.8a3.875 3.875 0 0 1-1.006-1.942H3.875v19.41h19.41V29.019a3.882 3.882 0 0 1-1.942-1.013Z"
            data-name="Path 1327"
          />
        </g>
        <g fill="#fff" data-name="Layer 1-2">
          <path
            d="M46.292 31.894a16.393 16.393 0 0 0-7.837-1.87 11.935 11.935 0 0 0-5.392.933 2.964 2.964 0 0 0-1.87 2.661 2.493 2.493 0 0 0 1.8 2.444 17.051 17.051 0 0 0 5.392.792 36.344 36.344 0 0 1 4.386.289 11.315 11.315 0 0 1 3.306.719 4.185 4.185 0 0 1 2.731 4.17 5.039 5.039 0 0 1-2.517 4.459c-1.653 1.078-4.1 1.581-7.189 1.581q-6.363 0-10.353-2.8l1.006-1.439a16.553 16.553 0 0 0 8.987 2.589 12.7 12.7 0 0 0 6.039-1.15 3.525 3.525 0 0 0 2.086-3.236 2.755 2.755 0 0 0-1.942-2.731 17.987 17.987 0 0 0-5.75-.933 35.388 35.388 0 0 1-4.17-.289 13.477 13.477 0 0 1-3.092-.647 3.729 3.729 0 0 1-2.731-3.739 4.456 4.456 0 0 1 2.372-4.026c1.581-1.006 3.811-1.439 6.759-1.439 3.953 0 6.973.719 9.131 2.156l-1.15 1.509Z"
            data-name="Path 1318"
          />
          <path
            d="M38.312 27.979c4.015 0 7.047.719 9.27 2.2a.25.25 0 0 1 .06.36l-1.15 1.509a.251.251 0 0 1-.317.07 16.046 16.046 0 0 0-7.719-1.84 11.664 11.664 0 0 0-5.275.9 2.727 2.727 0 0 0-1.736 2.44 2.255 2.255 0 0 0 1.643 2.213 17.023 17.023 0 0 0 5.3.773 36.19 36.19 0 0 1 4.417.291 11.277 11.277 0 0 1 3.369.738 4.422 4.422 0 0 1 2.88 4.4 5.275 5.275 0 0 1-2.63 4.668c-1.65 1.076-4.114 1.621-7.326 1.621a17.946 17.946 0 0 1-10.5-2.848.25.25 0 0 1-.061-.348l1.006-1.439a.25.25 0 0 1 .338-.068 16.322 16.322 0 0 0 8.853 2.55 12.416 12.416 0 0 0 5.919-1.119 3.294 3.294 0 0 0 1.957-3.017 2.515 2.515 0 0 0-1.782-2.5h-.01a17.69 17.69 0 0 0-5.663-.913 35.343 35.343 0 0 1-4.193-.291 13.708 13.708 0 0 1-3.141-.66 3.977 3.977 0 0 1-2.9-3.974 4.673 4.673 0 0 1 2.488-4.236c1.595-1.01 3.785-1.48 6.903-1.48Zm8.768 2.469c-2.117-1.325-4.99-1.969-8.768-1.969-2.977 0-5.144.458-6.625 1.4a4.218 4.218 0 0 0-2.256 3.815 3.48 3.48 0 0 0 2.571 3.506 13.25 13.25 0 0 0 3.03.632h.017a35.6 35.6 0 0 0 4.134.285 18.125 18.125 0 0 1 5.837.952 3.01 3.01 0 0 1 2.1 2.962 3.757 3.757 0 0 1-2.216 3.455 12.875 12.875 0 0 1-6.16 1.181 16.808 16.808 0 0 1-8.918-2.5l-.726 1.038a17.643 17.643 0 0 0 10 2.616c3.113 0 5.486-.518 7.053-1.54a4.807 4.807 0 0 0 2.4-4.249 4.343 4.343 0 0 0-.625-2.381 4.176 4.176 0 0 0-1.953-1.558h-.007a10.854 10.854 0 0 0-3.228-.7h-.023a36.384 36.384 0 0 0-4.346-.284 17.46 17.46 0 0 1-5.476-.809 2.738 2.738 0 0 1-1.955-2.677 3.184 3.184 0 0 1 2-2.882 12.11 12.11 0 0 1 5.509-.962 16.8 16.8 0 0 1 7.766 1.8Z"
            data-name="Path 1318 - Outline"
          />
          <path
            d="M73.896 47.781H55.564V28.874h17.612v1.581H57.217v6.47h11.07v1.581h-11.07v7.55h16.607l.072 1.725Z"
            data-name="Path 1319"
          />
          <path
            d="M73.896 48.031H55.564a.25.25 0 0 1-.25-.25V28.874a.25.25 0 0 1 .25-.25h17.612a.25.25 0 0 1 .25.25v1.581a.25.25 0 0 1-.25.25H57.467v5.97h10.82a.25.25 0 0 1 .25.25v1.581a.25.25 0 0 1-.25.25h-10.82v7.05h16.357a.25.25 0 0 1 .25.24l.072 1.725a.25.25 0 0 1-.25.26Zm-18.082-.5h17.821l-.051-1.225H57.217a.25.25 0 0 1-.25-.25v-7.55a.25.25 0 0 1 .25-.25h10.82v-1.081h-10.82a.25.25 0 0 1-.25-.25v-6.47a.25.25 0 0 1 .25-.25h15.709v-1.081H55.814Z"
            data-name="Path 1319 - Outline"
          />
          <path
            d="M93.811 28.874a5.712 5.712 0 0 1 4.17 1.509 5.537 5.537 0 0 1 1.509 4.1 5.2 5.2 0 0 1-1.653 4.026 6.321 6.321 0 0 1-4.459 1.509H81.3v7.764h-1.725v-18.91l14.236.002Zm-.144 9.562a3.99 3.99 0 0 0 2.875-1.006 4.175 4.175 0 0 0 1.006-2.948c0-2.661-1.509-3.953-4.528-3.953H81.23v7.909h12.437Z"
            data-name="Path 1320"
          />
          <path
            d="M79.574 28.622h14.237a5.977 5.977 0 0 1 4.346 1.582 5.8 5.8 0 0 1 1.582 4.274 5.446 5.446 0 0 1-1.732 4.208 6.546 6.546 0 0 1-4.629 1.576H81.55v7.514a.25.25 0 0 1-.25.25h-1.725a.25.25 0 0 1-.25-.25V28.872a.25.25 0 0 1 .25-.25Zm13.8 11.143a6.068 6.068 0 0 0 4.288-1.441 4.971 4.971 0 0 0 1.573-3.843A5.32 5.32 0 0 0 97.8 30.56a5.492 5.492 0 0 0-3.993-1.435H79.824v18.406h1.225v-7.514a.25.25 0 0 1 .25-.25ZM81.226 30.28h11.79c3.171 0 4.778 1.414 4.778 4.2a4.441 4.441 0 0 1-1.07 3.115l-.009.01a4.241 4.241 0 0 1-3.016 1.079H81.226a.25.25 0 0 1-.25-.25v-7.909a.25.25 0 0 1 .254-.245Zm15.135 6.982a3.967 3.967 0 0 0 .937-2.775 3.465 3.465 0 0 0-1.046-2.786 4.853 4.853 0 0 0-3.233-.917h-11.54v7.409h12.187a3.775 3.775 0 0 0 2.695-.931Z"
            data-name="Path 1320 - Outline"
          />
          <path
            d="M121.202 30.526h-8.987V47.78h-1.725V30.526h-8.987v-1.58h19.7v1.58Z"
            data-name="Path 1321"
          />
          <path
            d="M101.503 28.696h19.7a.25.25 0 0 1 .25.25v1.581a.25.25 0 0 1-.25.25h-8.737v17a.25.25 0 0 1-.25.25h-1.725a.25.25 0 0 1-.25-.25v-17h-8.737a.25.25 0 0 1-.25-.25v-1.581a.25.25 0 0 1 .249-.25Zm19.448.5h-19.2v1.081h8.737a.25.25 0 0 1 .25.25v17h1.225v-17a.25.25 0 0 1 .25-.25h8.736Z"
            data-name="Path 1321 - Outline"
          />
          <path
            d="M127.097 47.781h-1.725V28.874h1.725Z"
            data-name="Path 1322"
          />
          <path
            d="M127.097 48.031h-1.725a.25.25 0 0 1-.25-.25V28.874a.25.25 0 0 1 .25-.25h1.725a.25.25 0 0 1 .25.25v18.907a.25.25 0 0 1-.25.25Zm-1.475-.5h1.225V29.124h-1.225Z"
            data-name="Path 1322 - Outline"
          />
          <path
            d="M159.449 47.781h-1.725V30.524h-.072l-10.426 13.442-10.5-13.442h-.072v17.254h-1.725V28.874h2.661l9.634 12.581 9.634-12.581h2.589v18.907Z"
            data-name="Path 1323"
          />
          <path
            d="M159.449 48.031h-1.725a.25.25 0 0 1-.25-.25V31.165l-10.051 12.959a.249.249 0 0 1-.2.1.25.25 0 0 1-.2-.1l-10.12-12.962v16.62a.25.25 0 0 1-.25.25h-1.725a.25.25 0 0 1-.25-.25V28.874a.25.25 0 0 1 .25-.25h2.661a.25.25 0 0 1 .2.1l9.435 12.322 9.435-12.322a.25.25 0 0 1 .2-.1h2.589a.25.25 0 0 1 .25.25V47.785a.25.25 0 0 1-.249.246Zm-1.475-.5h1.225V29.124h-2.216l-9.559 12.483a.25.25 0 0 1-.4 0l-9.559-12.483h-2.288v18.407h1.225v-17a.25.25 0 0 1 .25-.25h.072a.25.25 0 0 1 .2.1l10.3 13.189 10.229-13.189a.25.25 0 0 1 .2-.1h.072a.25.25 0 0 1 .25.25Z"
            data-name="Path 1323 - Outline"
          />
          <path
            d="M186.409 47.78h-1.942l-2.589-4.889h-13.3l-2.589 4.889h-1.942l9.992-18.834h2.517l9.854 18.834Zm-5.392-6.542-5.68-10.712h-.289l-5.678 10.712Z"
            data-name="Path 1324"
          />
          <path
            d="M186.409 48.026h-1.942a.25.25 0 0 1-.221-.133l-2.519-4.756h-13l-2.519 4.756a.25.25 0 0 1-.221.133h-1.942a.25.25 0 0 1-.221-.367l9.994-18.833a.25.25 0 0 1 .221-.133h2.517a.25.25 0 0 1 .221.134l9.854 18.834a.25.25 0 0 1-.221.366Zm-1.792-.5h1.379l-9.592-18.334h-2.215l-9.727 18.334h1.376l2.519-4.756a.25.25 0 0 1 .221-.133h13.3a.25.25 0 0 1 .221.133Zm-3.6-6.042h-11.646a.25.25 0 0 1-.221-.367l5.678-10.712a.25.25 0 0 1 .221-.133h.289a.25.25 0 0 1 .221.133l5.678 10.721a.25.25 0 0 1-.221.367Zm-11.23-.5h10.814l-5.407-10.2Z"
            data-name="Path 1324 - Outline"
          />
          <path
            d="m20.84 21.468-2.8-2.8.5-.5 2.8 2.8a5 5 0 0 1 1.944-1.008V4H3.875v19.41h15.959a3.887 3.887 0 0 1 1.006-1.942Z"
            data-name="Path 1325"
          />
          <path
            d="M25.443 4v15.96a3.887 3.887 0 0 1 1.942 1.006l2.8-2.8.5.5-2.8 2.8a5 5 0 0 1 1.006 1.942h15.962V4Z"
            data-name="Path 1326"
          />
          <path
            d="m21.343 28.006-2.8 2.8-.5-.5 2.8-2.8a3.875 3.875 0 0 1-1.006-1.942H3.875v19.41h19.41V29.019a3.882 3.882 0 0 1-1.942-1.013Z"
            data-name="Path 1327"
          />
        </g>
      </g>
    </svg>
  );
}

