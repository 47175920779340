export default function SelectViewIcon() {
  return (
    <svg
      role="img"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      width={20}
      height={20}
      fill="currentColor"
    >
      <path d="M206.783-515.478q-44.305 0-75.153-30.848-30.848-30.848-30.848-75.153v-143.043q0-44.305 30.848-75.153 30.848-30.848 75.153-30.848h546.434q44.305 0 75.153 30.848 30.848 30.848 30.848 75.153v143.043q0 44.305-30.848 75.153-30.848 30.848-75.153 30.848H206.783Zm0-106.001h546.434v-143.043H206.783v143.043Zm0 532.002q-44.305 0-75.153-30.848-30.848-30.848-30.848-75.153v-143.043q0-44.305 30.848-75.153 30.848-30.848 75.153-30.848h546.434q44.305 0 75.153 30.848 30.848 30.848 30.848 75.153v143.043q0 44.305-30.848 75.153-30.848 30.848-75.153 30.848H206.783Zm0-106.001h546.434v-143.043H206.783v143.043Zm0-569.044v143.043-143.043Zm0 426.001v143.043-143.043Z"/>
    </svg>
  );
}
