import ReactMapGl, {
  Source,
  Layer,
  MapRef,
  Marker,
} from "react-map-gl/maplibre";
import "maplibre-gl/dist/maplibre-gl.css";
import { SeptimaSearch, searchController } from "../SeptimaSearch";
import { useCallback, useMemo, useRef, useState } from "react";
import { towgs84 } from "../util/project";
import SelectRadioCard from "../Components/SelectRadioCard";
import SeptimaWatermark from "../Components/Icons/SeptimaWatermark";
import { MapGeoJSONFeature, Popup } from "maplibre-gl";
import { Range } from "../util/color";
import Legend from "./Legend";
import SplashScreen from "./SplashScreen";
import Attrib from "./Attrib";
import Vilkår from "./Vilkår";
import { min_width_820, useMediaQuery } from "../hooks/useMediaQuery";
import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import SelectViewIcon from "../Components/Icons/SelectViewIcon";
import LegendIcon from "../Components/Icons/LegendIcon";
import { updateFeatures } from "../util/map";
import MapPopup from "./MapPopup";
const lineWidthStops = {
  thin: [
    [6, 1],
    [9.5, 1.5],
  ],
  thick: [
    [9.5, 2.5],
    [14, 6],
  ],
  selected: [
    [6, 3],
    [14, 7.5],
  ],
};
export default function Map() {
  const [cursor, setCursor] = useState<string>("auto");
  const setCursorPointer = useCallback(() => setCursor("pointer"), []);
  const setCursorAuto = useCallback(() => setCursor(""), []);
  const [currentSelected, setCurrentSelected] =
    useState<MapGeoJSONFeature | null>(null);
  const [view, setView] = useState<"grund" | "ejendom">("ejendom");
  const [showZoomInToView, setShowZoomInToView] = useState(true);
  const matches = useMediaQuery(min_width_820);
  const [gældendeForeløbig, setGældendeForeløbig] = useState<
    "gældende" | "new2020" | "foreløbig"
  >("foreløbig");
  const [ranges, setRanges] = useState<
    | {
        ejendom_foreløpig: Range;
        grund_foreløpig: Range;
        ejendom_gaeldende: Range;
        grund_gaeldende: Range;
        ejendom_new2020: Range;
        grund_new2020: Range;
      }
    | undefined
  >();
  let [vilkårIsOpen, setVilkårIsOpen] = useState(false);

  const mapRef = useRef<MapRef>(null);

  const [currentMarker, setCurrentMarker] = useState<{
    pos: [number, number];
    title: string;
  } | null>(null);
  const popup = useMemo(() => {
    return new Popup().setText(currentMarker?.title ?? "");
  }, [currentMarker]);

  function getColorProperty() {
    if (view === "ejendom") {
      return gældendeForeløbig === "foreløbig"
        ? "mappedejendom_forelopig"
        : gældendeForeløbig === "gældende"
        ? "mappedejendom_gaeldende"
        : "mappedejendom_new2020";
    }
    return gældendeForeløbig === "foreløbig"
      ? "mappedgrund_forelopig"
      : gældendeForeløbig === "gældende"
      ? "mappedgrund_gaeldende"
      : "mappedgrund_new2020";
  }

  function getLabel() {
    if (view === "ejendom") {
      return gældendeForeløbig === "foreløbig"
        ? "propertyvalueLabel"
        : gældendeForeløbig === "gældende"
        ? "currentPropertyValueLabel"
        : "new2020PropertyValueLabel";
    }
    return gældendeForeløbig === "foreløbig"
      ? "groundvalueLabel"
      : gældendeForeløbig === "gældende"
      ? "currentGroundValueLabel"
      : "new2020GroundValueLabel";
  }

  function getCurrentRange(): Range {
    if (view === "ejendom") {
      return gældendeForeløbig === "foreløbig"
        ? ranges!.ejendom_foreløpig
        : gældendeForeløbig === "gældende"
        ? ranges!.ejendom_gaeldende
        : ranges!.ejendom_new2020;
    }
    return gældendeForeløbig === "foreløbig"
      ? ranges!.grund_foreløpig
      : gældendeForeløbig === "gældende"
      ? ranges!.grund_gaeldende
      : ranges!.grund_new2020;
  }

  return (
    <>
      <SplashScreen mapRef={mapRef} setCurrentMarker={setCurrentMarker} />
      <Vilkår isOpen={vilkårIsOpen} setIsOpen={setVilkårIsOpen} />
      <ReactMapGl
        cursor={cursor}
        hash={"pos"}
        onLoad={(e) => {
          e.target.resize();
          e.target.touchZoomRotate.disableRotation();
        }}
        dragRotate={false}
        ref={mapRef}
        touchPitch={false}
        attributionControl={false}
        // touchZoomRotate={false}
        pitchWithRotate={false}
        initialViewState={{
          bounds: [
            [7.9, 58],
            [12.9, 54.5],
          ],
          bearing: 0,
        }}
        mapStyle={"/style.json"}
        maxBounds={[
          [1, 50],
          [18, 62],
        ]}
        maxZoom={19}
        onMouseMove={(e) => {
          const feature = e.target.queryRenderedFeatures(e.point as any, {
            layers: ["ejendomme-fill"],
          })[0];
          if (feature) {
            setCursorPointer();
          } else {
            setCursorAuto();
          }
        }}
        onClick={(e) => {
          const clickedFeats = e.target.queryRenderedFeatures(e.point, {
            layers: ["ejendomme-fill"],
          });
          if (clickedFeats.length > 0) {
            const feat = clickedFeats[0];
            console.log(feat.properties);
            setCurrentSelected(feat);
          } else {
            setCurrentSelected(null);
          }
        }}
        onMoveEnd={async (e) => {
          if (e.viewState.zoom < 12) {
            if (!showZoomInToView) setShowZoomInToView(true);
            (e.target.getSource("fgb") as any)?.setData({
              type: "FeatureCollection",
              features: [],
            });
            (e.target.getSource("labels") as any)?.setData({
              type: "FeatureCollection",
              features: [],
            });
            setRanges(undefined);
            return;
          }

          if (showZoomInToView) setShowZoomInToView(false);
          const {
            fc,
            ejdrange,
            grundrange,
            ejdrangeGaeldende,
            grundrangeGaeldende,
            ejdrangeNew2020,
            grundrangeNew2020,
            labels,
          } = await updateFeatures(e.target);
          (e.target.getSource("fgb") as any)?.setData(fc);
          (e.target.getSource("labels") as any)?.setData(labels);
          if (fc.features.length === 0) {
            setRanges(undefined);
          } else {
            setRanges({
              ejendom_foreløpig: ejdrange,
              grund_foreløpig: grundrange,
              ejendom_gaeldende: ejdrangeGaeldende,
              grund_gaeldende: grundrangeGaeldende,
              ejendom_new2020: ejdrangeNew2020,
              grund_new2020: grundrangeNew2020,
            });
          }
        }}
      >
        <div className="map-row-1">
          <div className="c-search-wrapper">
            <SeptimaSearch
              controller={searchController}
              onResult={(result) => {
                if (result.geometry) {
                  const test = towgs84(
                    result.geometry.coordinates[0],
                    result.geometry.coordinates[1]
                    );
                  setCurrentMarker({ pos: test, title: result.title });
                  mapRef.current?.flyTo({
                    center: test,
                    zoom: 17,
                  });
                }
              }}
            />
          </div>
          {matches && (
            <>
              {showZoomInToView && (
                <div className="c-select-card card">
                  Zoom ind for at se ejendomsvurderinger
                </div>
              )}
              {!showZoomInToView && (
                <SelectRadioCard
                  setValue={setView}
                  value={view}
                  gældendeForeløbig={gældendeForeløbig}
                  setGældendeForeløbig={setGældendeForeløbig}
                />
              )}
            </>
          )}
        </div>
        {matches && (
          <div className="map-row-2">
            <a
              className="septima-map-logo"
              href="https://septima.dk/"
              rel="noreferrer"
              target="_blank"
            >
              <span className="sr-only">Udviklet af Septima</span>
              <SeptimaWatermark />
            </a>
            {ranges && (
              <Legend
                range={getCurrentRange()}
                legendOpacity={0.8}
                grundEllerEjendom={view}
              />
            )}
          </div>
        )}
        {currentSelected && (
          <MapPopup
            close={() => setCurrentSelected(null)}
            feature={currentSelected}
            key={
              currentSelected
                ? currentSelected.properties.vurderingsejendomid
                : "0"
            }
          />
        )}
        {!matches && (
          <Tab.Group defaultIndex={0}>
            <Tab.List className="c-mobile-nav">
              {showZoomInToView && (
                <p className="w-100 text-center">
                  Zoom ind for at se ejendomsvurderinger
                </p>
              )}
              {!showZoomInToView && (
                <>
                  <Tab as={Fragment}>
                    {({ selected }) => (
                      <button
                        className={`c-mobile-nav__btn${
                          selected ? " text-primary" : " text-secondary"
                        }`}
                      >
                        <SelectViewIcon />
                        <span className="smaller">Vælg visning</span>
                      </button>
                    )}
                  </Tab>
                  <Tab as={Fragment}>
                    {({ selected }) => (
                      <button
                        className={`c-mobile-nav__btn${
                          selected ? " text-primary" : " text-secondary"
                        }`}
                      >
                        <LegendIcon />
                        <span className="smaller">Farver i kortet</span>
                      </button>
                    )}
                  </Tab>
                </>
              )}
            </Tab.List>
            <Tab.Panels className="c-mobile-nav__view">
              <Tab.Panel>
                {!showZoomInToView && (
                  <SelectRadioCard
                    setValue={setView}
                    value={view}
                    gældendeForeløbig={gældendeForeløbig}
                    setGældendeForeløbig={setGældendeForeløbig}
                  />
                )}
              </Tab.Panel>
              <Tab.Panel>
                {ranges && (
                  <Legend
                    range={getCurrentRange()}
                    legendOpacity={0.8}
                    grundEllerEjendom={view}
                  />
                )}
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        )}

        <Attrib setVilkårIsOpen={setVilkårIsOpen} />
        <Source
          type="geojson"
          id="fgb"
          data={{
            type: "FeatureCollection",
            features: [],
          }}
        >
          <Layer
            id={"ejendomme-fill"}
            type="fill"
            minzoom={10}
            paint={{
              "fill-color": [
                "match",
                ["get", getColorProperty()],
                "N/A",
                "#babfc3",
                [
                  "interpolate",
                  ["linear"],
                  ["get", getColorProperty()],
                  0,
                  "#FFFFC2",
                  20,
                  "#CCDCB5",
                  40,
                  "#98BAA8",
                  60,
                  "#6A9799",
                  80,
                  "#447586",
                  100,
                  "#195473",
                ],
              ],
              "fill-opacity": 0.8,
            }}
          />
          <Layer
            id={"ejendoemme-line"}
            type="line"
            paint={{
              "line-width": {
                stops: [
                  [11, 0.4],
                  [14, 1.5],
                ],
              } as any,
              "line-color": "#fff",
            }}
            minzoom={5}
          />
          <Layer
            id={"selected-ejendom-line"}
            minzoom={5}
            type="line"
            filter={[
              "==",
              "vurderingsejendomid",
              currentSelected
                ? currentSelected.properties.vurderingsejendomid
                : "",
            ]}
            paint={
              {
                "line-width": {
                  stops: lineWidthStops.selected,
                },
                "line-color": "#0C4E6C",
              } as any // todo: fix error
            }
          />
        </Source>
        <Source
          type="geojson"
          id="labels"
          data={{
            type: "FeatureCollection",
            features: [],
          }}
        >
          <Layer
            minzoom={15}
            id="ejendomme-label"
            type="symbol"
            layout={{
              "text-field": ["get", getLabel()],

              "text-font": [
                "proxima-nova Bold",
                "Open Sans Semibold",
                "Arial Unicode MS Bold",
              ],

              "text-size": 13,
            }}
            paint={{
              "text-color": "#fff",
              "text-halo-color": [
                "interpolate",
                ["linear"],
                ["get", getColorProperty()],
                0,
                "#6FB49C",
                100,
                "#05212e",
              ],
              "text-halo-width": 2,
              "text-halo-blur": 1,
            }}
          />
        </Source>

        {currentMarker && (
          <>
            <Marker
              longitude={currentMarker.pos[0]}
              latitude={currentMarker.pos[1]}
              popup={popup}
            />
          </>
        )}
      </ReactMapGl>
    </>
  );
}
