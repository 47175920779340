import Color from "color";

export type Range = { high: number; low: number };

/**
 * get the highest and lowest number in an array
 */
export const getNumberRange = (numbers: (number | string)[]): Range => {
  return numbers.reduce(
    (prev, curr) => {
      if (typeof curr === "string") return prev;
      if (curr > prev.high) prev.high = curr;
      if (curr < prev.low) prev.low = curr;
      return prev;
    },
    { high: Number.MIN_VALUE, low: Number.MAX_VALUE }
  );
};

export const mapNumberToNewRange = (
  num: number | string,
  oldRange: Range,
  newRange: Range
) => {
  if (typeof num === "string") return num;
  const response =
    (num - oldRange.low) *
      ((newRange.high - newRange.low) / (oldRange.high - oldRange.low)) +
    newRange.low;
  if (response > newRange.high) return newRange.high;
  if (response < newRange.low) return newRange.low;
  return response;
};

export const mapValues0to100 = (
  vals: (number | string)[],
  customMinMax?: {
    min: number;
    max: number;
  }
) => {
  // const numberVals = vals.filter((v) => typeof v === "number") as number[];
  const oldRange = customMinMax
    ? { high: customMinMax.max, low: customMinMax.min }
    : getNumberRange(vals);
  return vals.map((num) => {
    return mapNumberToNewRange(num, oldRange, { high: 100, low: 0 });
  });
};

export type GradientStep = { color: Color; stepValue: number };

const mixColors = (
  value: number,
  fromGradientStep: GradientStep,
  toGradientStep: GradientStep
) => {
  if (!toGradientStep) {
    debugger;
  }

  return fromGradientStep.color.mix(
    toGradientStep.color,
    (value - fromGradientStep.stepValue) /
      (toGradientStep.stepValue - fromGradientStep.stepValue)
  );
};

export const mapValuesToColorGradient = (
  value: number,
  colorGradient: {
    steps: GradientStep[];
  }
) => {
  if (isNaN(value)) return Color("#f8f7f3");
  const equalStepVal = colorGradient.steps.find((cg) => cg.stepValue === value);
  if (equalStepVal) return equalStepVal.color;
  const fromGradientStep = colorGradient.steps.filter(
    (step) => step.stepValue < value
  );
  const toGradientStep = colorGradient.steps.filter(
    (step) => step.stepValue > value
  );
  const c = mixColors(
    value,
    fromGradientStep[fromGradientStep.length - 1],
    toGradientStep[0] ?? fromGradientStep[fromGradientStep.length - 1]
  );
  return c;
};

export const gradients = {
  skala: [
    {
      color: Color("#FFFFC2"),
      stepValue: 0,
    },
    {
      color: Color("#CCDCB5"),
      stepValue: 20,
    },
    {
      color: Color("#98BAA8"),
      stepValue: 40,
    },
    {
      color: Color("#6A9799"),
      stepValue: 60,
    },
    {
      color: Color("#447586"),
      stepValue: 80,
    },
    {
      color: Color("#195473"),
      stepValue: 100,
    },
  ],
  "red-yellow-blue": [
    {
      color: Color("#890024"),
      stepValue: 0,
    },
    {
      color: Color("#A94138"),
      stepValue: 10,
    },
    {
      color: Color("#C86E4F"),
      stepValue: 20,
    },
    {
      color: Color("#E19E6F"),
      stepValue: 30,
    },
    {
      color: Color("#F2CD97"),
      stepValue: 40,
    },
    {
      color: Color("#FFFFC2"),
      stepValue: 50,
    },
    {
      color: Color("#CCDCB5"),
      stepValue: 60,
    },
    {
      color: Color("#98BAA8"),
      stepValue: 70,
    },
    {
      color: Color("#6A9799"),
      stepValue: 80,
    },
    {
      color: Color("#447586"),
      stepValue: 90,
    },
    {
      color: Color("#195473"),
      stepValue: 100,
    },
  ],
  "red-white-blue": [
    { color: Color("#6f0016"), stepValue: 0 },
    { color: Color("#b6001e"), stepValue: 10 },
    { color: Color("#fd0020"), stepValue: 20 },
    { color: Color("#ff4456"), stepValue: 30 },
    { color: Color("#ff8b92"), stepValue: 40 },
    { color: Color("#eee"), stepValue: 50 },
    { color: Color("#bdd7e7"), stepValue: 60 },
    { color: Color("#6baed6"), stepValue: 70 },
    { color: Color("#3182bd"), stepValue: 80 },
    { color: Color("#253494"), stepValue: 90 },
    { color: Color("#14145b"), stepValue: 100 },
  ],
};
export type ColorScheme = keyof typeof gradients;
