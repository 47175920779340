import proj4 from "proj4";
proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs +type=crs");
proj4.defs(
  "EPSG:25832",
  "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs"
);

export function towgs84(x: number, y: number): [number, number] {
  return proj4("EPSG:25832", "EPSG:4326", [x, y]);
}
