import { deserialize } from "flatgeobuf/lib/mjs/geojson.js";
import { throttle } from "lodash";
import { getNumberRange, mapValues0to100 } from "../util/color";
import { Map as MaplibreMap } from "maplibre-gl";
import area from "@turf/area";
import { IGeoJsonFeature } from "flatgeobuf";
import { polygon } from "@turf/helpers";
import centerOfMass from "@turf/center-of-mass";
function fgBoundingBox(map: MaplibreMap) {
  const { lng, lat } = map.getCenter();
  const { _ne } = map.getBounds();
  const xsize = _ne.lng - lng;
  const ysize = _ne.lat - lat;

  return {
    minX: lng - xsize,
    minY: lat - ysize,
    maxX: lng + xsize,
    maxY: lat + ysize,
  };
}

async function updateFeaturess(map: MaplibreMap) {
  const bbox = fgBoundingBox(map);
  let i = 0;
  const fc: any = { type: "FeatureCollection", features: [] };
  let iter = deserialize(
    process.env.REACT_APP_FGB_PATH ??
      "https://vurderingspris.dk/vurddb-maa-ikke-downloades.fgb",
    bbox
  );
  let labels = {
    type: "FeatureCollection",
    features: [],
  } as any;
  for await (let feature of iter as AsyncGenerator<
    IGeoJsonFeature,
    any,
    unknown
  >) {
    try {
      if (!feature.properties) feature.properties = {};
      const areaProperty = area(feature);
      const propertyvalueLabel =
        feature.properties.propertyvalue?.toLocaleString("da-DK") + " kr" ?? "";
      const groundvalueLabel =
        feature.properties.groundvalue?.toLocaleString("da-DK") + " kr" ?? "";
      const currentGroundValueLabel = feature.properties.grundvaerdibeloeb_sum
        ? feature.properties.grundvaerdibeloeb_sum.toLocaleString("da-DK") +
          " kr"
        : "-";
      const currentPropertyValueLabel = feature.properties
        .ejendomvaerdibeloeb_sum
        ? feature.properties.ejendomvaerdibeloeb_sum.toLocaleString("da-DK") +
          " kr"
        : "-";
      const new2020PropertyValueLabel = feature.properties.ejendomsvaerdi_2020
        ? feature.properties.ejendomsvaerdi_2020?.toLocaleString("da-DK") +
          " kr"
        : "";
      if (!feature.properties.ejendomsvaerdi_2020)
        feature.properties.ejendomsvaerdi_2020 = "N/A";

      const new2020GroundValueLabel = feature.properties.grundvaerdi_2020
        ? feature.properties.grundvaerdi_2020?.toLocaleString("da-DK") + " kr"
        : "";
      if (!feature.properties.grundvaerdi_2020)
        feature.properties.grundvaerdi_2020 = "N/A";
      feature.properties = {
        ...feature.properties,
        area: areaProperty,
        propertyvalueLabel,
        groundvalueLabel,
        currentGroundValueLabel,
        currentPropertyValueLabel,
        new2020PropertyValueLabel,
        new2020GroundValueLabel,
      };
      console.log(feature.properties);

      fc.features.push({ ...feature, id: i });
    } catch (error) {
      // debugger;
    }

    i += 1;
  }

  const stuff = fc.features.reduce(
    (acc, s) => {
      acc.propertyValuesPerSquareMeter.push(
        Math.round(s.properties.propertyvalue / s.properties.area)
      );
      acc.currentPropertyValuesPerSquareMeter.push(
        s.properties.ejendomvaerdibeloeb_sum
          ? Math.round(s.properties.ejendomvaerdibeloeb_sum / s.properties.area)
          : 0
      );
      acc.groundValuesPerSquareMeter.push(
        Math.round(s.properties.groundvalue / s.properties.area)
      );
      acc.currentGroundValuesPerSquareMeter.push(
        s.properties.grundvaerdibeloeb_sum
          ? Math.round(s.properties.grundvaerdibeloeb_sum / s.properties.area)
          : 0
      );
      acc.new2020GroundValuesPerSquareMeter.push(
        s.properties.grundvaerdi_2020
          ? typeof s.properties.grundvaerdi_2020 === "number"
            ? Math.round(s.properties.grundvaerdi_2020 / s.properties.area)
            : s.properties.grundvaerdi_2020
          : 0
      );

      acc.new2020PropertyValuesPerSquareMeter.push(
        s.properties.ejendomsvaerdi_2020
          ? typeof s.properties.ejendomsvaerdi_2020 === "number"
            ? Math.round(s.properties.ejendomsvaerdi_2020 / s.properties.area)
            : s.properties.ejendomsvaerdi_2020
          : 0
      );
      return acc;
    },
    {
      propertyValuesPerSquareMeter: [],
      currentPropertyValuesPerSquareMeter: [],
      groundValuesPerSquareMeter: [],
      currentGroundValuesPerSquareMeter: [],
      new2020GroundValuesPerSquareMeter: [],
      new2020PropertyValuesPerSquareMeter: [],
    }
  );

  const ejdrange = getNumberRange(stuff.propertyValuesPerSquareMeter);
  const ejd =
    stuff.propertyValuesPerSquareMeter.length === 1
      ? [50]
      : mapValues0to100(stuff.propertyValuesPerSquareMeter, {
          min: ejdrange.low,
          max: ejdrange.high,
        });

  const ejdrangeGaeldende = getNumberRange(
    stuff.currentPropertyValuesPerSquareMeter
  );
  const ejdGaeldende =
    stuff.currentPropertyValuesPerSquareMeter.length === 1
      ? [50]
      : mapValues0to100(stuff.currentPropertyValuesPerSquareMeter, {
          min: ejdrangeGaeldende.low,
          max: ejdrangeGaeldende.high,
        });

  const ejdrangeNew2020 = getNumberRange(
    stuff.new2020PropertyValuesPerSquareMeter
  );
  const ejdNew2020 =
    stuff.new2020PropertyValuesPerSquareMeter.length === 1
      ? [50]
      : mapValues0to100(stuff.new2020PropertyValuesPerSquareMeter, {
          min: ejdrangeNew2020.low,
          max: ejdrangeNew2020.high,
        });

  const grundrange = getNumberRange(stuff.groundValuesPerSquareMeter);
  const grund =
    stuff.groundValuesPerSquareMeter.length === 1
      ? [50]
      : mapValues0to100(stuff.groundValuesPerSquareMeter, {
          min: grundrange.low,
          max: grundrange.high,
        });

  const grundrangeGaeldende = getNumberRange(
    stuff.currentGroundValuesPerSquareMeter
  );
  const grundGaeldende =
    stuff.currentGroundValuesPerSquareMeter.length === 1
      ? [50]
      : mapValues0to100(stuff.currentGroundValuesPerSquareMeter, {
          min: grundrangeGaeldende.low,
          max: grundrangeGaeldende.high,
        });

  const grundrangeNew2020 = getNumberRange(
    stuff.new2020GroundValuesPerSquareMeter
  );
  const grundNew2020 =
    stuff.new2020GroundValuesPerSquareMeter.length === 1
      ? [50]
      : mapValues0to100(stuff.new2020GroundValuesPerSquareMeter, {
          min: grundrangeNew2020.low,
          max: grundrangeNew2020.high,
        });

  fc.features = fc.features.map((s, i) => {
    const properties = {
      ...s.properties,
      mappedejendom_forelopig: ejd[i],
      mappedgrund_forelopig: grund[i],
      mappedgrund_gaeldende: grundGaeldende[i],
      mappedejendom_gaeldende: ejdGaeldende[i],
      mappedgrund_new2020: grundNew2020[i],
      mappedejendom_new2020: ejdNew2020[i],
    };

    if (s.geometry.type === "MultiPolygon") {
      const polygons = s.geometry.coordinates.map((c) => polygon(c));
      const centerPoints = polygons.map((p) => {
        const center = centerOfMass(p);
        center.properties = properties;
        return center;
      });
      labels.features.push(...centerPoints);
    }

    return {
      ...s,
      properties,
    };
  });

  return {
    fc,
    ejdrange,
    grundrange,
    grundrangeGaeldende,
    grundrangeNew2020,
    ejdrangeGaeldende,
    ejdrangeNew2020,
    labels,
  };
}

export const updateFeatures: typeof updateFeaturess = throttle(
  updateFeaturess,
  1000
);
