import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

export default function Vilkår(props: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { isOpen, setIsOpen } = props;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="splash__root splash__root--data"
        onClose={() => setIsOpen(false)}
      >
        <div className="splash__bg" />
        <div className="splash__content">
        <Dialog.Panel className="splash__content-inner">
        <div className="splash__content-inner-container">
          <Dialog.Title as="h2" className="h1">Data & vilkår</Dialog.Title>
            <div className='texts mt-4' style={{ fontSize: "1.2rem"}}>
              <p>
                    Data er hentet fra <a href="https://www.vurderingsportalen.dk/" rel="noreferrer"
                target="_blank">vurderingsportalen.dk</a> og koblet med
                    grunddata hentet fra <a href="https://datafordeler.dk/" rel="noreferrer"
                target="_blank">Datafordeleren</a>.
                  </p>
                  <p className="mt-3">Kortet viser de ejendomme, der er omfattet af den <a href="https://www.vurderingsportalen.dk/ejerbolig/ejendomsvurdering/foreloebige-vurderinger" rel="noreferrer"
                target="_blank">Foreløbig vurdering 2022</a> - dog bortset fra ejerlejligheder.</p>
                  <p className="mt-3">
                   Henvendelser vedrørende vilkår, brug af screenshots mv kan
                    rettes til <a href="mailto:kontakt@septima.dk">kontakt@septima.dk</a> eller på tlf 7230 0672.
              </p>
            </div>
          <button className="dialog-btn" onClick={() => setIsOpen(false)}>Tilbage til kortet</button>
          </div>
        </Dialog.Panel>
        </div>
      </Dialog>
    </Transition>
  );
}
