import { useLayoutEffect, useRef, useState } from "react";
import {
  ISearchController,
  ISeptimaSearchResultItem,
  ISeptimaSearchResultType,
} from "./interfaces/types";
import { useCombobox } from "downshift";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import ResultItem from "./components/ResultItem";
import LoadingIndicator from "./components/LoadingIndicator";
import SearchIcon from "../Components/Icons/SearchIcon";

interface ISeptimaSearchProps {
  controller: ISearchController;
  limit?: number;
  onResult: (result: ISeptimaSearchResultItem) => void;
  name?: string;
}

function SeptimaSearchAutocomplete(props: ISeptimaSearchProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { limit = 6, onResult, controller, name } = props;
  const [searchInputValue, setSearchInputValue] = useState("");
  const [source, setSource] = useState<string | null>(null);
  const [type, setType] = useState<ISeptimaSearchResultType | undefined>(
    undefined
  );
  const [cursorPosition, setCursorPosition] = useState(-1);

  useLayoutEffect(() => {
    if (cursorPosition > -1 && inputRef.current) {
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }
  }, [cursorPosition]);
  const { data, isLoading } = useQuery<ISeptimaSearchResultItem[]>({
    queryKey: [
      "septima-search",
      searchInputValue,
      source,
      type?.id ?? undefined,
    ],
    queryFn: async function () {
      const result = await controller.fetchData(
        searchInputValue,
        null,
        limit,
        null,
        source,
        type?.id ?? null
      );
      return result.getAllResults();
    },
  });
  const items = data ?? [];
  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    inputValue,
    setInputValue,
  } = useCombobox({
    defaultHighlightedIndex: 0,
    items,
    onInputValueChange({ inputValue }) {
      setSearchInputValue(inputValue ?? "");
    },
    itemToString(item) {
      return item && item.newquery
        ? item.newquery.replace("<select></select>", "")
        : item && item.newquery === null
        ? item.title
        : searchInputValue;
    },
    onSelectedItemChange({ selectedItem }) {
      if (!selectedItem) return;
      const { newquery, type, source } = selectedItem;
      setType(type);
      setSource(source);
      onResult(selectedItem);
      setCursorPosition(newquery?.indexOf("<select></select>") ?? -1);
    },
    stateReducer(state, actionandchanges) {
      const { changes, type } = actionandchanges;
      const resultHasGeometry = !!changes.selectedItem?.geometry;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: resultHasGeometry ? false : true,
          };
        default:
          return changes;
      }
    },
  });

  //TODO: Maybe add debounce
  // const debouncedInputValue = useDebounce(inputValue, 500);
  // useEffect(() => {
  //   if (debouncedInputValue) setInputValue(debouncedInputValue);
  // }, [debouncedInputValue, setInputValue]);

  return (
    <div className="septima-search">
      <div className="septima-search-input-container">
        {/* {type && (
          <ChipLabel
            label={type.plural}
            onResetClick={() => setType(undefined)}
          />
        )} */}
        <input
          {...getInputProps({
            ref: inputRef,
            placeholder: "Søg på adresse",
            type: "text",
            className: "septima-search-input",
            onKeyDown: (e) => {
              switch (e.key) {
                case "Backspace": {
                  if (!inputValue) setType(undefined);
                }
              }
            },
            autoComplete: "off",
          })}
          name={name}
        />
        <div className="septima-search-input-icon">
          {isLoading && (
            <span>
              <LoadingIndicator />
            </span>
          )}
          {!isLoading && (type || inputValue) && (
            <button
              className="septima-search-input-reset-button"
              onClick={() => {
                setInputValue("");
                setType(undefined);
              }}
            >
              <svg
                aria-hidden="true"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                width={22}
                height={22}
                viewBox="0 0 48 48"
              >
                <path
                  fill="currentColor"
                  d="M24 26.8 13.85 36.95q-.6.6-1.4.6-.8 0-1.4-.6-.6-.6-.6-1.4 0-.8.6-1.4L21.2 24 11.05 13.85q-.6-.6-.6-1.4 0-.8.6-1.4.6-.6 1.4-.6.8 0 1.4.6L24 21.2l10.15-10.15q.6-.6 1.4-.6.8 0 1.4.6.6.6.6 1.4 0 .8-.6 1.4L26.8 24l10.15 10.15q.6.6.6 1.4 0 .8-.6 1.4-.6.6-1.4.6-.8 0-1.4-.6Z"
                />
              </svg>
            </button>
          )}
          {!isLoading && !(type || inputValue) && <SearchIcon />}
        </div>
      </div>
      <ul
        {...getMenuProps({
          className: "septima-search-result-container",
        })}
      >
        {isOpen && (
          <>
            {items.map((item, index) => {
              return (
                <ResultItem
                  key={`${item.id}-${item.title}`}
                  item={item}
                  highlighted={highlightedIndex === index}
                  {...getItemProps({
                    index,
                    item,
                  })}
                />
              );
            })}
          </>
        )}
      </ul>
    </div>
  );
}

const queryClient = new QueryClient({
  // defaultOptions: { queries: { staleTime: Infinity } },
});
export default function SeptimaSearch(props: ISeptimaSearchProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <SeptimaSearchAutocomplete {...props} />
    </QueryClientProvider>
  );
}
