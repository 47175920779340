import SeptimaLogo from "../Components/Icons/SeptimaLogo";

export default function Header() {

    return (
  <header className="c-header">
          <a
            className="c-header-logo"
              href="https://septima.dk/"
              rel="noreferrer"
              target="_blank"
              title="Gå til Septima.dk"
            >
              <span className="sr-only">Gå til Septima.dk</span>
              <SeptimaLogo />
        </a>
    </header>
    );
  }