export default function SeotimaLogo() {
    return (
<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="130px" height="37px" viewBox="0 0 182.209 44">
  <g id="Layer_1-2" data-name="Layer 1-2" transform="translate(-16.81 -11.06)">
    <path id="Path_1329" data-name="Path 1329" d="M539.737,507.168a16.39,16.39,0,0,0-7.828-1.867,11.866,11.866,0,0,0-5.386.935,2.963,2.963,0,0,0-1.867,2.658,2.494,2.494,0,0,0,1.8,2.443,17.006,17.006,0,0,0,5.386.791,36.419,36.419,0,0,1,4.382.288,11.341,11.341,0,0,1,3.3.719,4.185,4.185,0,0,1,2.73,4.166,5.034,5.034,0,0,1-2.515,4.454c-1.651,1.079-4.094,1.58-7.184,1.58q-6.357,0-10.343-2.8l1.007-1.436a16.55,16.55,0,0,0,8.979,2.586,12.714,12.714,0,0,0,6.033-1.148,3.524,3.524,0,0,0,2.083-3.231,2.758,2.758,0,0,0-1.939-2.73,17.977,17.977,0,0,0-5.746-.935,35.5,35.5,0,0,1-4.166-.288,13.518,13.518,0,0,1-3.09-.647,3.728,3.728,0,0,1-2.73-3.735,4.452,4.452,0,0,1,2.371-4.022c1.58-1.007,3.806-1.436,6.752-1.436,3.95,0,6.968.719,9.123,2.155l-1.151,1.5h0Z" transform="translate(-480.545 -468.273)" fill="#151515"/>
    <path id="Path_1330" data-name="Path 1330" d="M1085.345,536.789H1067.03V517.97h17.6v1.58h-15.948v6.465h11.063v1.58h-11.062v7.613h16.592l.072,1.582Z" transform="translate(-998.625 -482.022)" fill="#151515"/>
    <path id="Path_1331" data-name="Path 1331" d="M1569.1,516.51a5.71,5.71,0,0,1,4.166,1.508,5.529,5.529,0,0,1,1.508,4.094,5.2,5.2,0,0,1-1.651,4.022,6.3,6.3,0,0,1-4.454,1.508H1556.6V535.4h-1.723V516.582c0-.072,14.222-.072,14.222-.072Zm-.144,9.483a3.99,3.99,0,0,0,2.874-1.007,4.171,4.171,0,0,0,1.007-2.946c0-2.658-1.508-3.95-4.526-3.95h-11.782v7.9h12.426v0Z" transform="translate(-1462.509 -480.634)" fill="#151515"/>
    <path id="Path_1332" data-name="Path 1332" d="M2020,519.55h-8.979v17.239H2009.3V519.55h-8.979v-1.58H2020v1.58h0Z" transform="translate(-1886.065 -482.022)" fill="#151515"/>
    <path id="Path_1333" data-name="Path 1333" d="M2487,536.785h-1.723V517.966H2487Z" transform="translate(-2347.188 -482.018)" fill="#151515"/>
    <path id="Path_1334" data-name="Path 1334" d="M2704.012,536.789h-1.723V519.55h-.072L2691.8,532.982l-10.487-13.433h-.072v17.239h-1.723V517.97h2.658l9.626,12.57,9.626-12.57h2.586v18.819h-.005Z" transform="translate(-2531.932 -482.022)" fill="#151515"/>
    <path id="Path_1335" data-name="Path 1335" d="M3293.37,536.789h-1.939l-2.586-4.885h-13.289l-2.587,4.885h-1.939l9.986-18.819h2.515l9.84,18.819h0Zm-5.389-6.537-5.674-10.7h-.288l-5.674,10.7h11.635Z" transform="translate(-3094.352 -482.022)" fill="#151515"/>
    <path id="Path_1336" data-name="Path 1336" d="M33.812,28.518l-2.8-2.8.5-.5,2.8,2.8a3.878,3.878,0,0,1,1.939-1.007V11.06H16.86V30.454H32.807a3.868,3.868,0,0,1,1-1.937Z" transform="translate(-0.048)" fill="#0d9474"/>
    <path id="Path_1337" data-name="Path 1337" d="M455.04,11.17V27.117a3.882,3.882,0,0,1,1.939,1.007l2.8-2.8.5.5-2.8,2.8a5,5,0,0,1,1.007,1.939h15.947V11.172S455.04,11.17,455.04,11.17Z" transform="translate(-416.678 -0.105)" fill="#0d9474"/>
    <path id="Path_1338" data-name="Path 1338" d="M34.268,451.733l-2.8,2.8-.5-.5,2.8-2.8a5,5,0,0,1-1.007-1.939H16.81v19.394H36.2V452.737A4.521,4.521,0,0,1,34.268,451.733Z" transform="translate(0 -416.72)" fill="#0d9474"/>
  </g>
</svg>
    );
  }