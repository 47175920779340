import { RadioGroup } from "@headlessui/react";
import CheckIcon from "./Icons/CheckIcon";

export default function SelectRadioCard(props: {
  value: "grund" | "ejendom";
  setValue: (value: "grund" | "ejendom") => void;
  gældendeForeløbig: "gældende" | "new2020" | "foreløbig";
  setGældendeForeløbig: (value: "gældende" | "foreløbig" | "new2020") => void;
}) {
  const { setValue, value, gældendeForeløbig, setGældendeForeløbig } = props;

  return (
    <RadioGroup
      value={gældendeForeløbig}
      onChange={(e) => setGældendeForeløbig(e)}
      className="c-select-card card pt-3"
    >
      <RadioGroup.Label className="text-muted text-uppercase fw-bold smaller">
        Vælg visning
      </RadioGroup.Label>
      <div className="c-select-card__items">
        <RadioGroup.Option value="gældende" className="c-select-card--focus">
          {({ checked }) => (
            <div
              className={`c-select-card__item${
                checked ? " c-select-card__item--checked" : ""
              }`}
            >
              <span className="checkmark" aria-hidden="true" role="img">
                {checked && <CheckIcon />}
              </span>
              <span className="c-select-card__item-label">
                Tidligere vurdering
              </span>
              {checked && <SubRadioGroup value={value} setValue={setValue} />}
            </div>
          )}
        </RadioGroup.Option>
        <RadioGroup.Option value="new2020" className="c-select-card--focus">
          {({ checked }) => (
            <div
              className={`c-select-card__item${
                checked ? " c-select-card__item--checked" : ""
              }`}
            >
              <span className="checkmark" aria-hidden="true" role="img">
                {checked && <CheckIcon />}
              </span>
              <span className="c-select-card__item-label">
                2020-vurdering
              </span>
              {checked && <SubRadioGroup value={value} setValue={setValue} />}
            </div>
          )}
        </RadioGroup.Option>
        <RadioGroup.Option value="foreløbig" className="c-select-card--focus">
          {({ checked }) => (
            <div
              className={`c-select-card__item${
                checked ? " c-select-card__item--checked" : ""
              }`}
            >
              <span className="checkmark" aria-hidden="true" role="img">
                {checked && <CheckIcon />}
              </span>
              <span className="c-select-card__item-label">
                Foreløbig 2022-vurdering
              </span>
              {checked && <SubRadioGroup value={value} setValue={setValue} />}
            </div>
          )}
        </RadioGroup.Option>
      </div>
    </RadioGroup>
  );
}

export const SubRadioGroup = (props: {
  value: "grund" | "ejendom";
  setValue: (value: "grund" | "ejendom") => void;
}) => {
  const { value, setValue } = props;

  return (
    <div className="btn-group" role="group" aria-label="Vælg">
      <input
        type="radio"
        className="btn-check"
        name="btnradio"
        id="btnradio1"
        autoComplete="off"
        checked={value === "ejendom"}
        onChange={() => setValue("ejendom")}
      />
      <label htmlFor="btnradio1">Ejendomsværdi</label>
      {/*<span>/</span>
      <input
        type="radio"
        className="btn-check"
        name="btnradio"
        id="btnradio2"
        autoComplete="off"
        checked={gældendeForeløbig === "new2020"}
        onChange={() => setGældendeForeløbig("new2020")}
      />
      <label htmlFor="btnradio2">Nye 2020-vurderinger</label> */}
      <span>/</span>
      <input
        type="radio"
        className="btn-check"
        name="btnradio"
        id="btnradio3"
        autoComplete="off"
        checked={value === "grund"}
        onChange={() => setValue("grund")}
      />
      <label htmlFor="btnradio3">Grundværdi</label>
    </div>
  );
};
