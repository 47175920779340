export default function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.512"
      height="16.512"
      viewBox="0 0 16.512 16.512"
    >
      <g transform="translate(1 1)">
        <g transform="translate(0)">
          <circle
            id="Ellipse_336"
            data-name="Ellipse 336"
            cx="5.878"
            cy="5.878"
            r="5.878"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <line
            id="Line_15"
            data-name="Line 15"
            x1="4.067"
            y1="4.067"
            transform="translate(10.031 10.031)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
}
