import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useRef } from "react";
import { towgs84 } from "../util/project";
import { MapRef } from "react-map-gl/maplibre";
import { SeptimaSearch, searchController } from "../SeptimaSearch";
import Header from "./Header";

export default function SplashScreen(props: {
  mapRef: React.RefObject<MapRef>;
  setCurrentMarker: React.Dispatch<
    React.SetStateAction<{
      pos: [number, number];
      title: string;
    } | null>
  >;
}) {
  const { mapRef, setCurrentMarker } = props;
  let completeButtonRef = useRef(null)
  let [isOpen, setIsOpen] = useState(true);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="splash__root" initialFocus={completeButtonRef} onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="splash__bg" />
          </Transition.Child>

          <div className="splash__content">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="splash__content-inner">
                <Header />
                <div className="splash__content-inner-container">
                  <h1 className="text-primary h3 text-uppercase">
                    Ejendomsvurdering 2022
                  </h1>
                  <Dialog.Title as="h2" className="h1 mt-1">
                    Se ejendomsvurderinger på kort
                  </Dialog.Title>
                  <div className="mt-4" ref={completeButtonRef}>
                    <SeptimaSearch
                      controller={searchController}
                      onResult={(result) => {
                        if (result.geometry) {
                          const test = towgs84(
                            result.geometry.coordinates[0],
                            result.geometry.coordinates[1]
                          );
                          setCurrentMarker({ pos: test, title: result.title });

                          mapRef.current?.flyTo({
                            center: test,
                            zoom: 17,
                          });
                          setIsOpen(false);
                        }
                      }}
                    />
                  </div>

                  <div className="mt-4 text-center">
                    <button type="button" className="btn" onClick={closeModal}>
                      Gå til kort
                    </button>
                  </div>
                </div>
                <footer>                  
                    <div className='texts mt-auto'>
                      <p>
                        Data er hentet fra <a href="https://www.vurderingsportalen.dk/" rel="noreferrer"
                    target="_blank">vurderingsportalen.dk</a> og koblet med
                        grunddata hentet fra <a href="https://datafordeler.dk/" rel="noreferrer"
                    target="_blank">Datafordeleren</a>.
                      </p>
                      <p className="mt-3">Kortet viser de ejendomme, der er omfattet af den <a href="https://www.vurderingsportalen.dk/ejerbolig/ejendomsvurdering/foreloebige-vurderinger" rel="noreferrer"
                    target="_blank">Foreløbig vurdering 2022</a> - dog bortset fra ejerlejligheder.</p>
                      <p className="mt-3">
                       Henvendelser vedrørende vilkår, brug af screenshots mv kan
                        rettes til <a href="mailto:kontakt@septima.dk">kontakt@septima.dk</a> eller på tlf 7230 0672.
                      </p>
                    </div>
                </footer>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}