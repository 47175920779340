/* eslint-disable jsx-a11y/anchor-is-valid */

export default function Attrib(props: {
  setVilkårIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <div
      className="c-attribution"
    >
      <div
        className="maplibregl-ctrl-attrib"
        style={{
          backgroundColor: "#ffffff80",
          padding: "0 5px 0 1rem",
          lineHeight: "16px",
          display: "flex",
          gap: "0.5rem",
        }}
      >
        <a href="https://septima.dk/" target="_blank" rel="noopener noreferrer">
          © Septima
        </a>
        <div>
        <span>©{" "}</span>
        <a
          href="https://www.openstreetmap.org/copyright"
          target="_blank"
          rel="noopener noreferrer"
        >
          OpenStreetMap contributors
        </a>,{" "}<a
          href="https://gst.dk/"
          target="_blank"
          rel="noopener noreferrer"
        >
          GST
        </a>,{" "}<a
          href="https://sdfi.dk/"
          target="_blank"
          rel="noopener noreferrer"
        >
          SDFI
        </a>
        </div>
        {" | "}
        <a href="#" onClick={() => props.setVilkårIsOpen(true)}>
          Data & vilkår
        </a>
      </div>
    </div>
  );
}
